import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bekind from "../assets/dainty-grp/WHAT-IS-DHOLERA-SIR-home-page.webp";
import { useState } from "react";
import "../assets/css/information.css"
gsap.registerPlugin(ScrollTrigger);

const Information = () => {
  const [accordionState, setAccordionState] = useState({
    collapseOne: false,
    collapseTwo: false,
    collapseThree: false,
    collapseFour: false,
    collapseFive: false,
  });

  const handleAccordionClick = (accordionItem) => {
    setAccordionState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === accordionItem ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      setTimeout(() => {
        let tHero = gsap.context(() => {
          let animation__feature2 = gsap.utils.toArray(
            ".animation__feature2 .animation__plot"
          );
          if (device_width < 1023) {
            animation__feature2.forEach((item, i) => {
              gsap.set(item, { opacity: 0, y: 60 });
              let featured2Timeline = gsap.timeline({
                scrollTrigger: {
                  trigger: item,
                  start: "top center+=200",
                },
              });
              featured2Timeline.to(item, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
              });
            });
          } else {
            gsap.set(".animation__feature2 .animation__plot", {
              opacity: 0,
              y: 40,
            });
            gsap.to(".animation__feature2 .animation__plot", {
              scrollTrigger: {
                trigger: ".animation__feature2",
                start: "top center+=200",
              },
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power4.out",
              stagger: 0.3,
            });
          }
        });
        return () => tHero.revert();
      }, 1000);
    }
  }, []);
  return (
    <>
     <div className="has-smooth" id="has_smooth" />
  <button id="scroll_top" className="scroll-top">
        <i className="fa-solid fa-arrow-up" />
      </button>
      <div id="about-dholera">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <section  className="price__area pt-130 pb-140 two-sec">
            <div className="">
              <div className="row what-is-dholera-1024 animation__feature2">
                <div className="col-xxl-6 col-xl-6 col-lg-6 dholera-top animation__plot">
                  <div className="sec-title-wrapper">
                    <h3 className="sec-title title-anim dholera-sir animation__char_come">
                      What is Dholera SIR ?
                    </h3>
                    <p className="below-dholera-content animation__char_come">Covering about 920 sq km with vast land parcels, DSIR (Dholera Special Investment Region) stands as a paramount industrial initiative in India. Positioned strategically near Ahmedabad, Baroda, Rajkot, and Bhavnagar, it boasts top-notch ITC infrastructure and superb connectivity, conveniently located near airports and the Central Spine Expressway.</p>
                  </div>
                  <div className="faq__list-3 animation__char_come">
                    <div className="accordion" id="accordionExample">
                      
                    <div className="accordion-item animation__char_come">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            // onClick={() => handleAccordionClick("collapseOne")}
                          >
                            Role Model Greenfield city for Future Smart Cities
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className={`accordion-collapse collapse ${
                            accordionState.collapseOne ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className={`accordion-body ${
                            accordionState.collapseOne ? "show" : ""
                          }`}>
                            <p>
                            Dholera SIR exemplifies a pioneering greenfield city, serving as a blueprint for future smart urban developments. With sustainable infrastructure and strategic planning, it leads the way towards economic prosperity and environmental stewardship.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item animation__char_come">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="flase"
                            // onClick={() => handleAccordionClick("collapseTwo")}
                          >
                            India's 1st "PLATINUM" rated by IGBC
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className={`accordion-collapse collapse ${
                            accordionState.collapseTwo ? "show" : ""
                          }`}
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                            Dholera achieves India's first "Platinum" rating by IGBC, reflecting its exemplary commitment to sustainability and environmental standards, setting a benchmark for future smart city developments.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item animation__char_come">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="flase"
                            // onClick={() =>
                            //   handleAccordionClick("collapseThree")
                            // }
                          >
                            Self-sustained Eco-system
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className={`accordion-collapse collapse ${
                            accordionState.collapseThree ? "show" : ""
                          }`}
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                            Dholera epitomizes a self-sustained eco-system, integrating green technologies, efficient resource management, and eco-friendly practices, ensuring harmonious coexistence with nature while fostering economic growth and societal well-being.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item animation__char_come">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="flase"
                            // onClick={() => handleAccordionClick("collapseFour")}
                          >
                            Very well connected by Road, Rail, Sea and
                            International Airport
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className={`accordion-collapse collapse ${
                            accordionState.collapseFour ? "show" : ""
                          }`}
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                            Dholera enjoys robust connectivity through an intricate network of roads, railways, and a nearby sea port, complemented by its proximity to an international airport. This comprehensive transportation infrastructure facilitates efficient movement of goods and people, positioning Dholera as a key hub for domestic and international trade, commerce, and travel.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item animation__char_come">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="flase"
                            // onClick={() => handleAccordionClick("collapseFive")}
                          >
                            Dholera: Ahmedabad's Synergetic Sibling
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className={`accordion-collapse collapse ${
                            accordionState.collapseFive ? "show" : ""
                          }`}
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                            "Dholera: Ahmedabad's Synergetic Sibling" embodies a collaborative urban vision where Dholera complements Ahmedabad's growth, easing congestion and fostering innovation. Together, they symbolize Gujarat's journey towards sustainable development, blending tradition with modernity for inclusive prosperity and vibrant communities.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 mt-70 dholera-top animation__char_come animation__plot">
                  <img className="animation__plot" src={bekind} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
    </>
  );
};

export default Information;
