import React, { useState, useEffect } from 'react';
import close from "../assets/imgs/icon/close.png";
import axios from 'axios';
import hero3 from "../assets/dainty-grp/popup-img.webp";
import "../assets/css/contactpopup.css";
import { useNavigate } from 'react-router-dom';

const ContactPopUp = ({ closePopup, setIsPopupOpen, setDone, isPopupOpen }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    user_name: '',
    user_email: '',
    phone: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    // Validation for name (only characters allowed)
    if (name === 'user_name' && !/^[a-zA-Z]+$/.test(value)) {
      errorMessage = 'Please enter only characters';
    }
    if (name === 'user_email' && !/\S+@\S+\.\S+/.test(value)) {
      errorMessage = 'Please enter valid email address';
    }

    // Validation for phone (only numbers allowed, max length 10)
    if (name === 'phone' && (!/^\d{10}$/.test(value) || value.length > 10)) {
      errorMessage = 'Enter valid phone number';
    }

    setFormErrors({ ...formErrors, [name]: errorMessage });
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      setFormErrors({
        ...formErrors,
        user_name: emptyFields.includes('user_name') ? 'This field is required' : '',
        user_email: emptyFields.includes('user_email') ? 'This field is required' : '',
        phone: emptyFields.includes('phone') ? 'This field is required' : '',
      });
      return;
    }
    setFormData({
      ...formData,
      user_name: '',
      user_email: '',
      phone: '',
    });

    try {
      const response = await axios.post('https://daintygroupdholera.com/api/sendmailpopup.php', formData);

      if (response.data.message === 'Email Send Done !') {
        // setDone((prevDone) => !prevDone);
      
          navigate("/thank-you");
        
        setIsPopupOpen(false);
      }
    } catch (error) {
      console.error('Error during API request:', error);
    }
  };

  return (
    <div className='pop-background'>
      <div className="popup raw">
        <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6'>
          <img className='pop-img-dholera' src={hero3} />
        </div>
        <div className="popup-content col-xxl-7 col-xl-7 col-lg-6 col-md-6">
          <button className="popup-close" onClick={closePopup}>
            <img className='close-pop' src={close} />
          </button>
          <div className='contact__form-pop'>
            <form onSubmit={sendEmail}>
              <h2 className='enquire-now plot-txt-enquire'>Plots Starting From <br />
                ₹50 Lacs*
              </h2>
              <div className='row g-3 dis-contents'>
                <div className='col-xxl-12 col-xl-12 col-12'>
                  <input
                    type='text'
                    name='user_name'
                    placeholder='Name *'
                    value={formData.user_name}
                    onChange={handleChange}
                  />
                  {formErrors.user_name && <span className='error-message'>{formErrors.user_name}</span>}
                </div>
                <div className='col-xxl-12 col-xl-12 col-12'>
                  <input
                    type='email'
                    name='user_email'
                    placeholder='Email *'
                    value={formData.user_email}
                    onChange={handleChange}
                  />
                  {formErrors.user_email && <span className='error-message'>{formErrors.user_email}</span>}
                </div>
                <div className='col-xxl-12 col-xl-12 col-12'>
                  <input
                    type='number'
                    name='phone'
                    placeholder='Phone *'
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {formErrors.phone && <span className='error-message'>{formErrors.phone}</span>}
                </div>
              </div>
              <div className='row g-3'>
                <div className='col-12'>
                  <div className='btn_wrapper form-btn'>
                    <button className='wc-btn-primary btn-hover btn-item' type='submit'>
                      <span /> Invest <br />
                      Now <i className='fa-solid fa-arrow-right' />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPopUp;
