import React from 'react'
import hero1 from "../assets/dainty-grp/banner-3.webp";
import hero2 from "../assets/dainty-grp/banner-4.webp";
import hero3 from "../assets/dainty-grp/banner-5.webp";
import circle_line from "../assets/imgs/portfolio/circle-1.png";
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/slider.css"
const HeroSlider = () => {
  return (
    <>
    <div className='scrollbar' id="home"></div>
    <img className="circle-line" src={circle_line} alt="First slide" />
      <Carousel  interval={4000} data-bs-theme="dark">
        <Carousel.Item>
          <span className="slider-span-line"></span>
          <span className="slider-span-second-line"></span>
          <span className="slider-span-third-line"></span>
          <span className="slider-span-forth-line"></span>
          <img className="d-block w-100" src={hero1} alt="First slide" />

          <Carousel.Caption>
          <h6 className="heading-txt-slider animation__char_come">
              Invest in FUTURE!
              <br />
              <span className="middle-heading-txt animation__char_come">Invest in DHOLERA SIR</span>
              <br />
              Ready N.A. Commercial Plots
            </h6>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <span className="slider-span-line"></span>
          <span className="slider-span-second-line"></span>
          <span className="slider-span-third-line"></span>
          <span className="slider-span-forth-line"></span>
          <img className="d-block w-100" src={hero2} alt="Second slide" />
          <Carousel.Caption>
            <h6 className="heading-txt-slider animation__char_come">
            Smart Investment
              <br />
              <span className="middle-heading-txt animation__char_come">In Smart City DHOLERA</span>
              <br />
              Ready N.A. Residential Plots
            </h6>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <span className="slider-span-line"></span>
          <span className="slider-span-second-line"></span>
          <span className="slider-span-third-line"></span>
          <span className="slider-span-forth-line"></span>

          <img className="d-block w-100" src={hero3} alt="Third slide" />
          <Carousel.Caption>
            <h6 className="heading-txt-slider animation__char_come">
               India's First Greenfield
              <br />
              <span className="middle-heading-txt animation__char_come">Industrial Smart City</span>
              <br />
              Invest Now!
            </h6>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default HeroSlider