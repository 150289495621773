import React from "react";
import site_logo from "../assets/dainty-grp/dainty-_1_ (1).webp";
import "../assets/css/thankyou.css";
import { useEffect } from "react";
import circle_line from "../assets/imgs/portfolio/circle-1.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ThankYou = ({ onBack }) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add("thankyou-active");

    return () => {
      document.body.classList.remove("thankyou-active");
    };
  }, []);

  const handleBack = () => {
    navigate("/")
  };

  return (
    <>
    <Helmet>
    <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16564949825/3-JBCNSg9bAZEMGm5No9'});
          `}
        </script>
      </Helmet>
      <div>
        <div className={"thankyou-container"}>
          <img
            className="circle-line-thanks"
            src={circle_line}
            alt="First slide"
          />
          <span className="slider-span-line"></span>
          <span className="slider-span-second-line"></span>
          <span className="slider-span-third-line"></span>
          <span className="slider-span-forth-line"></span>
          <div className="header__logo-2 pb-30">
            <a href="index.html" className="logo-dark">
              <img src={site_logo} alt="Site Logo" />
            </a>
          </div>
          <div>
            <p className="thanks-txt">Thank you for contacting us</p>
            <p className="thanks-txt">We will get back to you soon!</p>
          </div>
          <div className="row g-3">
            <div className="col-12">
              <div className="btn_wrapper">
                <button
                  className="wc-btn-primary btn-hover btn-item"
                  onClick={() => handleBack()}
                >
                  <span /> Back to <br />
                  Home <i className="fa-solid fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
