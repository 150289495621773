import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../assets/css/canvas.css"
import site_logo from "../assets/dainty-grp/dainty-_1_ (1).webp";

const Canvas = ({ ofCanvasArea, bladeMode = "" }) => {
  const [accordion, setAccordion] = useState(0);
  const [subAccordion, setSubAccordion] = useState(0);
  const headerTitle = useRef();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        let rootParent = headerTitle.current.children;
        for (let i = 0; i < rootParent.length; i++) {
          let firstParent = rootParent[i].children;
          for (let j = 0; j < firstParent.length; j++) {
            if (firstParent[j].className.includes("header_title")) {
              let arr = firstParent[j].children[0].textContent.split("");
              let spanData = "";
              for (let k = 0; k < arr.length; k++) {
                if (arr[k] == " ") {
                  spanData += `<span style='width:2vw;'>${arr[k]}</span>`;
                } else {
                  spanData += `<span>${arr[k]}</span>`;
                }
              }
              let result = '<div class="menu-text">' + spanData + "</div>";
              firstParent[j].children[0].innerHTML = result;
            }
          }
        }
      }, 10);
    }
  }, []);
  const openData = (data) => {
    setAccordion(data);
  };
  const openSubData = (data) => {
    setSubAccordion(data);
  };
  const closeCanvas = () => {
    ofCanvasArea.current.style.opacity = "0";
    ofCanvasArea.current.style.visibility = "hidden";
    if (bladeMode) {
      let header_bg = bladeMode;
      header_bg.style.setProperty("mix-blend-mode", "exclusion");
    }
  };
  return (
    <>
      <div className="offcanvas__area" ref={ofCanvasArea}>
        <div className="offcanvas__body">
        
            <div className="offcanvas__menu-wrapper">
              <nav className="offcanvas__menu">
                <ul className="menu-anim title" ref={headerTitle}>
                  <div className="canvas-img">
                  <img className="dainty-canvas-logo" src={site_logo} alt="Site Logo" />

                  </div>
                  <div className="header_title">
                    <p className="canvas-list-menu"><a className="canvas-list-menu" href="#home">HOME</a></p>
                  </div>

                  <div className="header_title">
                  <p className="canvas-list-menu"><a className="canvas-list-menu" href="#plot">PLOTS</a></p>
                  </div>

                  <div className="header_title">
                  <p className="canvas-list-menu"><a className="canvas-list-menu" href="#about-dholera">ABOUT DHOLERA</a></p>
                  </div>

                  <div className="header_title">
                  <p className="canvas-list-menu"><a className="canvas-list-menu" href="#project">PROJECTS </a></p>
                  </div>

                  <div className="header_title">
                  <p className="canvas-list-menu"><a className="canvas-list-menu" href="#testimonial">TESTIMONIALS</a></p>
                  </div>

                  <div className="header_title">
                  <p className="canvas-list-menu"><a className="canvas-list-menu" href="#why-dainty">WHY DAINTY</a></p>
                  </div>
                </ul>
              </nav>
            </div>
          </div>

          <div className="offcanvas__close">
            <button type="button" onClick={closeCanvas}>
              <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </button>
          </div>
        </div>
    
    </>
  );
};

export default Canvas;
