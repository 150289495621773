import React from "react";
import aboutdolera from "../../src/assets/imgs/about/1/aboutdholera.jpg"
import brand1 from "../../src/assets/imgs/brand/1.png"
import brand2 from "../../src/assets/imgs/brand/2.png"
import brand3 from "../../src/assets/imgs/brand/3.png"
import brand4 from "../../src/assets/imgs/brand/4.png"
import brand5 from "../../src/assets/imgs/brand/5.png"
import brand6 from "../../src/assets/imgs/brand/6.png"
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const ExploreInfo = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      let tHero = gsap.context(() => {
        gsap.to(".about__img-2 img", {
          scale: 1.15,
          duration: 5,
          scrollTrigger: {
            trigger: ".about__img-2",
            start: "top bottom",
            markers: false,
            scrub: 1,
            end: "bottom center",
          },
        });
        gsap.to("body", {
          smooth: 1.35,
          effects: device_width < 1025 ? false : true,
          smoothTouch: false,
          normalizeScroll: false,
          ignoreMobileResize: true,
        });
      });
      return () => tHero.revert();
    }
  }, []);

  return (
    <>
      {/* Cursor Animation */}
      {/* <div className="cursor1" />
      <div className="cursor2" /> */}

      <div className="has-smooth" id="has_smooth" />

      <div id="smooth-wrapper">
        <div id="smooth-content">
          <section className="about__area-2">
            <div className="container pt-130 pb-110">
              <div className="row">
                <div className="col-xxl-12">
                  <div className="sec-title-wrapper">
                    <h2 className="sec-title title-anim">
                    About Dholera
                    </h2>
                  </div>
                  <div className="sec-text-wrapper">
                    <div className="sec-text text-anim">
                      <p>
                      Dholera is being built from scratch by undertaking land of 22 villages and developing it in a way for it to surpass advanced cities like Shanghai and Singapore in every aspect. Dholera SIR is basically a growing Greenfield Smart City within the boundaries of Ahmedabad. 
                     <br/>
                     <br/>
                    The Dholera SIR development project– has gained a lot of importance in a short span of time, since its ultimate features and benefits. Dholera Smart City Project is completely enjoying the assistance of the federal government and comprises of capability for personal segment contribution.  So hurry up & Buy Commercial Properties & Plot or buy land in dholera
                    & Residential Plot in Dholera. 
                      </p>
                    </div>
                    <div className="sec-btn btn_wrapper">
                      <a
                        className="wc-btn-pink btn-hover btn-item"
                        href="about.html"
                      >
                        <span /> Explore Us{" "}
                        <i className="fa-solid fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__img-2 pb-130">
              <img
                src={aboutdolera}
                alt="About Image"
                data-speed="0.2"
              />
            </div>
            <div className="container pb-140">
              <div className="row">
                <div className="col-xxl-12">
                  <h2 className="brand__title-2 title-anim">
                    We worked with global largest brands
                  </h2>
                  <div className="brand__list-2">
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand1} alt="Brand Logo" />
                    </div>
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand2} alt="Brand Logo" />
                    </div>
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand3} alt="Brand Logo" />
                    </div>
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand4} alt="Brand Logo" />
                    </div>
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand5} alt="Brand Logo" />
                    </div>
                    <div className="brand__item-2 fade_bottom">
                      <img src={brand6} alt="Brand Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    
    </>
  );
};

export default ExploreInfo;
