import React from 'react'
import Footer from './Footer'
import "../assets/css/disclaimer.css"
import HeaderTwo from './Headertwo'
const Disclaimer = () => {
  return (
    <div>
        <HeaderTwo/>
        <div className='disclaimer-container container'>
          <p className='disclaimer-txt-heading-main'>Disclaimer</p>
          <p className='disclaimer-txt-p'>
          The amenities, specifications, facilities, surrounding infrastructure, stock images and features shown and/or mentioned and the image renders used herein are purely indicative and for representational purposes and may differ from the actuals. Photographs of Exteriors, surroundings or location are digitally enhanced unless otherwise mentioned. Not all photos may have been shot at site. Products, features, light fittings, pictures, images, etc. shown as illustrations are for reference only.
          </p>
        </div>
        <Footer/>
    </div>
  )
}

export default Disclaimer