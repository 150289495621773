import React from 'react'
import ContactFrom from "./ContactFrom";
import LandLayout from "./LandLayout";
import Information from "./Information";
import ExploreInfo from "./ExploreInfo";
import GlobalBrands from "./GlobalBrands";
import ClientsFeedback from "./ClientsFeedback";
import ChooseUs from "./ChooseUs";
import Footer from "./Footer";
import HeroSlider from "./HeroSlider";
import InvestmentInfo from "./InvestmentInfo";
import TextContent from "./TextContent";
import DholeraMap from "./DholeraMap";
import HeaderMain from './HeaderMain';
import SmoothScroll from "../SmoothScroll/SmoothScroll";

const Home = () => {
  return (
    <div>
         <HeaderMain/>
      <SmoothScroll>
        <HeroSlider />
        <ContactFrom />
        <LandLayout />
        <Information />
        <TextContent />
        <DholeraMap />
        <InvestmentInfo />
        {/* <ExploreInfo /> */}
        <GlobalBrands />
        <ClientsFeedback />
      </SmoothScroll>
      <ChooseUs />
      <Footer />
    </div>
  )
}

export default Home