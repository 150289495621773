import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dholeramap from "../assets/dainty-grp/Dholera-Map-_1_ (1).webp";
import "../assets/css/dholeramap.css";
gsap.registerPlugin(ScrollTrigger);

const DholeraMap = () => {
  const bollet = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" x="0" y="0" viewBox="0 0 682.667 682.667" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><defs><clipPath id="b" clipPathUnits="userSpaceOnUse"><path d="M0 512h512V0H0Z" fill="#ad042b" opacity="1" data-original="#000000"></path></clipPath></defs><mask id="a"><rect width="100%" height="100%" fill="#ffffff" opacity="1" data-original="#ffffff"></rect></mask><g mask="url(#a)"><path d="m0 0-134.174-134.174-63.873 63.872" style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 473.365 251.884)" fill="none" stroke="#ad042b" stroke-width="40" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#000000" class="" opacity="1"></path><g clip-path="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)"><path d="M0 0c0-130.339-105.661-236-236-236S-472-130.339-472 0s105.661 236 236 236S0 130.339 0 0Z" style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="translate(492 256)" fill="none" stroke="#ad042b" stroke-width="40" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#000000" class="" opacity="1"></path></g></g></g></svg>`;
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      setTimeout(() => {
        let tHero = gsap.context(() => {
          let animation__feature2 = gsap.utils.toArray(
            ".map-contetnt-wrapper .animation__plot"
          );
          if (device_width < 1023) {
            animation__feature2.forEach((item, i) => {
              gsap.set(item, { opacity: 0, y: 60 });
              let featured2Timeline = gsap.timeline({
                scrollTrigger: {
                  trigger: item,
                  start: "top center+=200",
                },
              });
              featured2Timeline.to(item, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
              });
            });
          } else {
            gsap.set(".map-contetnt-wrapper .animation__plot", {
              opacity: 0,
              y: 40,
            });
            gsap.to(".map-contetnt-wrapper .animation__plot", {
              scrollTrigger: {
                trigger: ".map-contetnt-wrapper",
                start: "top center+=200",
              },
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power4.out",
              stagger: 0.3,
            });
          }
        });
        return () => tHero.revert();
      }, 1000);
    }
  }, []);
  return (
    <>
      <div className="has-smooth" id="has_smooth" />
    <section className="map-content-area">
      <div className="map-contetnt-wrapper raw">
        <div className="map-content animation__plot col-xxl-5 col-xl-5 col-lg-6 col-md-6">
          <h2 className="heading-dholera-content-txt-map animation__char_come">
          Advantages of Investing in Dholera 
          </h2>
          <div className="svg-bollet-dholera animation__char_come">
            <div
              className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }}
            />
            <p className="dholera-map-txt">
            Dholera SIR stands out among other smart cities in India due to its <span className="hightlight-seo">strategic location, strong government support,</span> and <span className="hightlight-seo"> comprehensive planning.</span> 
            </p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt">Investors in Dholera SIR <span className="hightlight-seo">enjoy various tax incentives </span> and benefits provided by the government. </p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt">
            The region's <span className="hightlight-seo">rapid development and growth potential </span>translate into promising investment returns.  
            </p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt">
            India’s First Smart City with an <span className="hightlight-seo">Artificial River Front will supply water uninterruptedly.</span>
            </p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt"><span className="hightlight-seo">Forbes rated it one of amongst top 12 fasted growing cities </span>in the  World.</p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt"> <span className="hightlight-seo"> India’s First Smart City </span>which is Free of Slums Waste Storage.</p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt">
            India’s First <span className="hightlight-seo">Greenfield Cargo and passenger International Airport.</span>
            </p>
          </div>
          <div className="svg-bollet-dholera animation__char_come">
            <div className="svg-pad-t" dangerouslySetInnerHTML={{ __html: bollet }} />
            <p className="dholera-map-txt">
           <span className="hightlight-seo">India's first platinum-rated city</span>  by the Indian Green Building Council (IGBC).
            </p>
          </div>
        </div>
        <div className="map animation__plot col-xxl-7 col-xl-7 col-lg-6 col-md-6 animation__char_come">
          <img
            priority
            style={{ width: "auto", height: "auto" }}
            src={dholeramap}
            alt="Service Image"
            className="service-mid-img map-img-mobile animation__plot"
          />
        </div>
      </div>
    </section>
    </>
  );
};

export default DholeraMap;
