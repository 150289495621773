import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import designquality from "../assets/dainty-grp/Bharat-Patel.webp";
import cleancode from "../assets/dainty-grp/Sneha-Shah.webp";
import instantsupport from "../assets/dainty-grp/Hardeep-Kour.webp";
import instantsupport1 from "../assets/dainty-grp/Jignesh-Patel.webp";
import instantsupport2 from "../assets/dainty-grp/Hetal-Desai.webp";
import deepakpatel from "../assets/imgs/testimonial/deepakpatel.jpg";
import mani from "../assets/imgs/testimonial/mani.jpg"
import Gurjinder  from "../assets/imgs/testimonial/Gurjinder.png"
import "../assets/css/swiper-bundle.min.css";
import Swiper from "swiper";
import "../assets/css/clientsfeedback.css"
import { useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

const ClientsFeedback = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = new Swiper(".testimonial__slider-4", {
      slidesPerView: 3,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".testimonial__pagination-4",
        clickable: true,
        type: "fraction",
      },
    });

    swiperRef.current = swiper;

    const updateSlidesPerView = () => {
      if (swiperRef.current) {
        const slidesPerView = window.innerWidth <= 500 ? 1 : 3 && window.innerWidth <= 768 ? 2 : 3;
        swiperRef.current.params.slidesPerView = slidesPerView;
        swiperRef.current.update();
      }
    };

    updateSlidesPerView();

    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      swiperRef.current.destroy();
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      setTimeout(() => {
        let tHero = gsap.context(() => {
          let animation__feature2 = gsap.utils.toArray(
            ".animation__feature2 .animation__plot"
          );
          if (device_width < 1023) {
            animation__feature2.forEach((item, i) => {
              gsap.set(item, { opacity: 0, y: 60 });
              let featured2Timeline = gsap.timeline({
                scrollTrigger: {
                  trigger: item,
                  start: "top center+=200",
                },
              });
              featured2Timeline.to(item, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
              });
            });
          } else {
            gsap.set(".animation__feature2 .animation__plot", {
              opacity: 0,
              y: 40,
            });
            gsap.to(".animation__feature2 .animation__plot", {
              scrollTrigger: {
                trigger: ".animation__feature2",
                start: "top center+=200",
              },
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power4.out",
              stagger: 0.3,
            });
          }
        });
        return () => tHero.revert();
      }, 1000);
    }
  }, []);

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <>
   

      {/* Scroll Smoother */}
      <div className="has-smooth" id="has_smooth" />
      {/* Go Top Button */}
      <button id="scroll_top" className="scroll-top">
        <i className="fa-solid fa-arrow-up" />
      </button>
      <div id="testimonials">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <main>
            <div className="home-wrapper-4">
              {/* Testimonial area start */}
              <section className="testimonial__area-4 animation__feature2 ">
                <div className="container g-0 line_4">
                  <div className="line-col-4">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="testimonial__sec-title text-anim">
                        <h2 className="sec-subtile-6">Testimonials</h2>
                        <h3 className="sec-title-6 title-anim voice-txt animation__plot animation__char_come">
                        Voices of Satisfaction <br />
                        Client Testimonials for Dainty Group
                        </h3>
                        <br />
                        <p className="animation__char_come">
                          Dainty Group's Investors Share Their Success Stories
                          and Positive Experiences.
                        </p>
                      </div>
                    </div>
                    <div className="swiper testimonial__slider-4">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={designquality}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                           
                            <p>
                              "Considering investing in Dholera is a wise
                              decision for your money and future."
                            </p>
                            <h5 className="testimonial__name-4">
                              Bharat Patel
                            </h5>
                            <h6 className="testimonial__role-4">Ahmedabad</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={cleancode}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                           
                            <p>
                              "Investing in Dholera was a delightful experience;
                              I received excellent assistance from Dainty
                              Group."
                            </p>
                            <h5 className="testimonial__name-4">Sneha Shah</h5>
                            <h6 className="testimonial__role-4">Surat</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={instantsupport}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                            
                            <p>
                              "Getting information about Dholera's plotting
                              schemes brought me immense joy! Investing with
                              Dainty Group was enjoyable."
                            </p>
                            <h5 className="testimonial__name-4">
                              Hardeep Singh
                            </h5>
                            <h6 className="testimonial__role-4">Canada</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={instantsupport1}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                           
                            <p>
                              "With Dainty Group's assistance, investing in
                              plots in Dholera became convenient. All
                              arrangements are excellent."
                            </p>
                            <h5 className="testimonial__name-4">
                              Jignesh Patel
                            </h5>
                            <h6 className="testimonial__role-4">Gandhinagar</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={instantsupport2}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                           
                            <p>
                              "Finding out about plotting schemes in Dholera led
                              me to a great deal with your help. Thank you,
                              Dainty Group!"
                            </p>
                            <h5 className="testimonial__name-4">Hetal Desai</h5>
                            <h6 className="testimonial__role-4">Rajkot</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={deepakpatel}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                            
                            <p>
                              "Investing in plots in Dholera with Dainty Group
                              helped me save time and money. And the plots are
                              in excellent locations."
                            </p>
                            <h5 className="testimonial__name-4">
                              Deepak Patel
                            </h5>
                            <h6 className="testimonial__role-4">Ahmedabad</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={mani}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                            
                            <p>
                              "Wonderful and authentic place Dainty dholera for investment, with numerous investing opportunities of commercial properties, real estate, residential place and so on ,in appropriate price guarantee, ensuring you get the finest agreement or deal on your future dreams."
                            </p>
                            <h5 className="testimonial__name-4">
                            Mani Kaur
                            </h5>
                            <h6 className="testimonial__role-4">Ludhiana</h6>
                          </div>
                        </div>
                        <div className="swiper-slide testimonial__slide-4 animation__char_come">
                          <img
                            className="testimonial__img-4"
                            src={Gurjinder}
                            alt="Testimonial Image"
                          />
                          <div className="testimonial__info-4">
                            
                            <p>
                              "I have been in contact with them from a long time. What they say they deliver. Staff is good in communication. Understands customer’s requirements."
                            </p>
                            <h5 className="testimonial__name-4">
                            Gurjinder Singh
                            </h5>
                            <h6 className="testimonial__role-4">Ambala</h6>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial__btn-4">
                        <div
                          className="prev-button swipper-btn"
                          onClick={handlePrevClick}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </div>
                        <div
                          className="next-button swipper-btn"
                          onClick={handleNextClick}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </div>
                        <div className="pagination testimonial__pagination-4">
                          <div className="pag"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Testimonial area end */}
            </div>
          </main>
        </div>
      </div>
      {/* All JS files */}
      </div>
    </>
  );
};

export default ClientsFeedback;
