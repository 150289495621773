import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import ThankYou from './ThankYou';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    user_name: '',
    user_email: '',
    phone: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    // Validation for name (only characters allowed)
    if (name === 'user_name' && !/^[a-zA-Z]+$/.test(value)) {
      errorMessage = 'Please enter only characters';
    }
    if (name === 'user_email' && !/\S+@\S+\.\S+/.test(value)) {
      errorMessage = 'Please enter  valid email address';
    }

    // Validation for phone (only numbers allowed, max length 10)
    if (name === 'phone' && (!/^\d{10}$/.test(value) || value.length > 10)) {
      errorMessage = 'Enter valid phone number';
    }

    setFormErrors({ ...formErrors, [name]: errorMessage });
    setFormData({ ...formData, [name]: value });
  };

  const handleBack = () => {
    setFormSubmitted(false);
  };

  const handleRecaptchaChange = (token) => {

    setRecaptchaToken(token);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    // Check if reCAPTCHA token is empty
    setTimeout(() => {
      if (!recaptchaToken) {
        console.error('reCAPTCHA token is empty');
        return;
      }
    }, 1000);
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      setFormErrors({
        ...formErrors,
        user_name: emptyFields.includes('user_name') ? 'This field is required' : '',
        user_email: emptyFields.includes('user_email') ? 'This field is required' : '',
        phone: emptyFields.includes('phone') ? 'This field is required' : '',
      });
      return;
    }
    setFormData({
      ...formData,
      user_name: '',
      user_email: '',
      phone: '',
    })
    try {
      const response = await axios.post('https://daintygroupdholera.com/api/sendmail.php', {
        ...formData,
        recaptchaToken: recaptchaToken,
      });


      if (response.data.message === 'Email Send Done !') {
          navigate("/thank-you");
      }
    } catch (error) {
      console.error('Error during API request:', error);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcyxpEpAAAAAJutoEE3Sva-6RbV-zhh32nTXcSP';
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='form-design'>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
        <div className='contact__form animation__char_come'>
          <form onSubmit={sendEmail}>
            <h2 className='enquire-now'>
              Plots Starting From <br />
              ₹50 Lacs*
            </h2>
            <div className='row g-3 dis-contents'>
              <div className='col-xxl-12 col-xl-12 col-12'>
                <input
                  type='text'
                  name='user_name'
                  placeholder='Name *'
                  value={formData.user_name}
                  onChange={handleChange}
                />
                {formErrors.user_name && <span className='error-message'>{formErrors.user_name}</span>}
              </div>
              <div className='col-xxl-12 col-xl-12 col-12'>
                <input
                  type='email'
                  name='user_email'
                  placeholder='Email *'
                  value={formData.user_email}
                  onChange={handleChange}
                />
                {formErrors.user_email && <span className='error-message'>{formErrors.user_email}</span>}
              </div>
              <div className='col-xxl-12 col-xl-12 col-12'>
                <input
                  type='number'
                  name='phone'
                  placeholder='Phone *'
                  value={formData.phone}
                  onChange={handleChange}
                />
                {formErrors.phone && <span className='error-message'>{formErrors.phone}</span>}
              </div>
            </div>
            <ReCAPTCHA
              sitekey='6LcyxpEpAAAAAJutoEE3Sva-6RbV-zhh32nTXcSP'
              onChange={(token) => handleRecaptchaChange(token)}
              size='invisible'
            />
            <div className='row g-3'>
              <div className='col-12'>
                <div className='btn_wrapper form-btn'>
                  <button className='wc-btn-primary btn-hover btn-item' type='submit'>
                    <span /> Invest <br />
                    Now <i className='fa-solid fa-arrow-right' />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {formSubmitted && <ThankYou onBack={handleBack} />}
    </div>
  );
};

export default ContactForm;
