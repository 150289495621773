import "./assets/css/bootstrap.min.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/progressbar.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/master.css";
import "./assets/css/customclass.css";
import "./assets/css/all.min.css";
import "./assets/css/locomotive-scroll.min.css";
import "./assets/css/master-dist.css";
import "./assets/css/master-dist.css.map";
import "./assets/css/meanmenu.min.css";
import "../src/main.scss";
import "../src/assets/css/header.css";
import "../src/assets/css/landlayout.css";
import { useState } from "react";
import ContactPopUp from "./pages/ContactPopUp";
import ThankYou from "./pages/ThankYou";
import whatsapp from "../src/assets/dainty-grp/whatsapp.png"
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [done, setDone] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handleBack = () => {
    setDone(false);
  };

  const openWhatsapp = () => {
    window.open("https://wa.me/+919802511001", "_blank");
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/disclaimer" element={<Disclaimer/>}/>
        <Route path="/thank-you" element={<ThankYou/>}/>
      </Routes>
      <button id="scroll_top" className="btn-scroll" onClick={scrollToTop}>
        <i className="fa-solid fa-arrow-up"></i>
      </button>
      <div onClick={openWhatsapp} className="whatsapp-stick">
        <img className="whatsapp-img" src={whatsapp} alt=""/>
      </div>
      <div class="cs-fooer-2">
        <div class="container">
          <div class="cs-bottom_footer">
            <div class="cs-bottom_footer_left">
              <a href="#footer-demo">
                <button onClick={openPopup} class="footer-button">
                  <span>INVEST NOW</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <ContactPopUp
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          closePopup={closePopup}
          setDone={setDone}
        />
      )}
      {done && <ThankYou onBack={handleBack} />}
    </>
  );
}

export default App;
