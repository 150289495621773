import React, { useState, useEffect, useRef } from "react";
import site_logo from "../assets/dainty-grp/dainty-_1_ (1).webp";
import menu_black from "../assets/imgs/icon/menu-black.png";
import ContactPopUp from "./ContactPopUp";
import ThankYou from "./ThankYou";
import "../assets/css/contactpopup.css";
import Canvas from "./Canvas";

const HeaderTwo = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [done, setDone] = useState(false);
  const ofCanvasArea = useRef();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openCanvas = () => {
    ofCanvasArea.current.style.opacity = "1";
    ofCanvasArea.current.style.visibility = "visible";
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleBack = () => {
    setDone(false);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      if (window.scrollY === 0) {
        // If already at the top, scroll without smooth behavior
        window.scrollTo({ top: section.offsetTop, behavior: "auto" });
      } else {
        // If not at the top, scroll with smooth behavior
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <header className={`header__area-3 ${isSticky ? "sticky-3" : ""}`}>
        <div className="header__inner-3">
          <div className="header__logo-2">
            <a href="#" className="logo-dark">
              <img
                className="dainty-header-logo"
                src={site_logo}
                alt="Site Logo"
              />
            </a>
            <a href="#" className="logo-light">
              <img
                src="assets/imgs/logo/site-logo-white-2.png"
                alt="Site Logo"
              />
            </a>
          </div>
          <div className="header__nav-2">
            <ul className="main-menu-3 menu-anim">
              <li className="has-megamenu">
                <a href="/" > Home </a>
              </li>
              <li>
                <a href="/privacy-policy"> Privacy policy </a>
              </li>
              <li className="has-megamenu">
                <a href="/disclaimer"> Disclaimer </a>
              </li>
            </ul>
          </div>
          <div className="header__nav-icon-3">
            <button className="search-icon" id="search_icon">
              <i className="fa-solid fa-magnifying-glass" />
            </button>
            <button className="search-icon" id="search_close">
              <i className="fa-solid fa-xmark" />
            </button>
            <button className="btn-buynow" onClick={openPopup}>
              Invest Now
            </button>
            <button onClick={openCanvas} id="open_offcanvas">
              <img src={menu_black} alt="Menubar Icon" />
            </button>
          </div>
        </div>
      </header>
      {isPopupOpen && (
        <ContactPopUp
          setDone={setDone}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          closePopup={closePopup}
        />
      )}
      {done && <ThankYou onBack={handleBack} />}
      <Canvas ofCanvasArea={ofCanvasArea} />
    </>
  );
};

export default HeaderTwo;

