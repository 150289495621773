import Service11 from "../assets/dainty-grp/Welcome-To-The-Dholera-Smart-City.webp";
import "../assets/css/textcontent.css";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TextContent = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      setTimeout(() => {
        let tHero = gsap.context(() => {
          let animation__feature2 = gsap.utils.toArray(
            ".animation__feature2 .animation__plot"
          );
          if (device_width < 1023) {
            animation__feature2.forEach((item, i) => {
              gsap.set(item, { opacity: 0, y: 60 });
              let featured2Timeline = gsap.timeline({
                scrollTrigger: {
                  trigger: item,
                  start: "top center+=200",
                },
              });
              featured2Timeline.to(item, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
              });
            });
          } else {
            gsap.set(".animation__feature2 .animation__plot", {
              opacity: 0,
              y: 40,
            });
            gsap.to(".animation__feature2 .animation__plot", {
              scrollTrigger: {
                trigger: ".animation__feature2",
                start: "top center+=200",
              },
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power4.out",
              stagger: 0.3,
            });
          }
        });
        return () => tHero.revert();
      }, 1000);
    }
  }, []);
  return (
    <>
      <div className="has-smooth" id="has_smooth" />
      <section className="service__area-6">
       
          <div className="row inherit-row">
            <div className="col-xxl-12">
              <div className="content-wrapper animation__feature2">
                <div className="mid-content animation__plot">
                  <div className="service__image animation__char_come">
                    <img
                      priority
                      style={{ width: "auto", height: "auto" }}
                      src={Service11}
                      alt="Service Image"
                      className="service-mid-img"
                    />
                  </div>
                </div>

                <div className="right-content">
                  <div className="service__items-6">
                    <div
                      className="service__item-6 has__service_animation animation__char_come animation__feature2"
                      id="service_1"
                      data-secid="1"
                    >
                      <div className="image-tab animation__char_come animation__plot">
                        <img
                          priority
                          style={{ width: "auto", height: "auto" }}
                          src={Service11}
                          alt="Service Image"
                          className="img-tab-container"
                        />
                      </div>

                      <div className="animation__service_page">
                        <h2 className="service__title-6 heading-dholera-content-txt animation__char_come">
                          Welcome To The <br></br>Dholera Smart City
                        </h2>
                        <p className="dholera-content-txt animation__char_come">
                          Planning to buy land, you should go for a plot in
                          Dholera SIR. Dholera Real Estate company is booming
                          because it is the first smart city in India. Making an
                          investment in a Dholera plot scheme is the right thing
                          for all the right reasons.
                        </p>
                        <p className="dholera-content-txt animation__char_come">
                        If you want to buy plots in Dholera, now is the best time to invest in residential and commercial plots as Dholera land price is going to shoot up high in a few months to a few years.  Dainty Group Dholera is carrying out major Industrial projects in Dholera SIR and we are the right choice when it comes to Buy Industrial land in Dholera as we are open for land sale booking right now.
                        </p>
                        <p className="dholera-content-txt animation__char_come">
                        All our residential and commercial land in Dholera are in locations that are directly accessible from the existing highway, proposed expressway, metro railway station and the airport. Your life is going to be more comfortable than you can ever imagine.
                        </p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      </section>
    </>
  );
};

export default TextContent;
