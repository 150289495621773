import React from "react";
import Footer from "./Footer";
import "../assets/css/privacypolicy.css";
import HeaderTwo from "./Headertwo";
const PrivacyPolicy = () => {
  return (
    <div>
      <HeaderTwo/>
      <div className="privacy-policy-container container">
        <p className="privacy-policy-txt-heading-main">Privacy policy</p>
        <p className="privacy-policy-txt-p">
          Danity Group Dholera is committed to protect and maintain the
          confidentiality of any information relating to our customers. We
          collect no information about you, other than information automatically
          collected and stored, when you visit our website unless you choose to
          provide that information to us.
        </p>

        <p className="privacy-policy-txt-heading">Information Automatically Collected And Stored</p>

        <p className="privacy-policy-txt-p">
          When you browse through any website, certain personal information
          about you can be collected. We automatically collect and temporarily
          store the following information about your visit:
        </p>
        <ul className="policy-ul">
          <li className="privacy-policy-txt-p">the name of the domain you use to access the Internet</li>
          <li className="privacy-policy-txt-p">the date and time of your visit</li>
          <li className="privacy-policy-txt-p">the pages you visited and</li>
          <li className="privacy-policy-txt-p">
            the address of the web site you came from when you came to visit
          </li>
        </ul>
        <p className="privacy-policy-txt-p">
          We use this information for statistical purposes and to help us make
          our site more useful to visitors. Unless it is specifically stated
          otherwise, no additional information will be collected about you.
        </p>

        <p className="privacy-policy-txt-heading">Personally Provided Information</p>

        <p className="privacy-policy-txt-p">
          You do not have to give us personal information to visit this website.
          If you choose to provide us with additional information about yourself
          through an e-mail message, form, survey, etc., we will only maintain
          the information as long as needed to respond to your question or to
          fulfill the stated purpose of the communication. However, all
          communications addressed to the web site are maintained, as required
          by law, for historical purposes. These communications are archived on
          a monthly basis, but are also protected by the Privacy Act which
          restricts our use of them, yet permits certain disclosures.
        </p>

        <p className="privacy-policy-txt-heading">Disclosure</p>

        <p className="privacy-policy-txt-p">
          Danity Group Dholera does not disclose, give, sell or transfer any
          personal information about our visitors, unless required for law
          enforcement or statute. For site security purposes and to ensure that
          this service remains available to all users, we employ software
          programs to monitor traffic to identify unauthorized attempts to
          upload or change information, or otherwise cause damage. In the event
          of authorized law enforcement investigations, and pursuant to any
          required legal process, information from these sources may be used to
          help identify an individual.
        </p>

        <p className="privacy-policy-txt-p">
          Danity Group Dholera will not be responsible in any manner for any
          direct or indirect damages that may arise out of the use of this web
          site. Avirahi Group of Companies has the right to change any part of
          the said content on the website at any time at its sole discretion.
        </p>

        <p className="privacy-policy-txt-heading">Consent </p>
        <p className="privacy-policy-txt-p">
          By using our website, you hereby consent to our privacy policy and
          agree to its terms and conditions.{" "}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
