import React, { useState, useEffect, useRef } from "react";
import site_logo from "../assets/dainty-grp/dainty-_1_ (1).webp";
import menu_black from "../assets/imgs/icon/menu-black.png";
import ContactPopUp from "./ContactPopUp";
import ThankYou from "./ThankYou";
import "../assets/css/contactpopup.css";
import Canvas from "./Canvas";

const HeaderMain = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [done, setDone] = useState(false);
  const ofCanvasArea = useRef();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openCanvas = () => {
    ofCanvasArea.current.style.opacity = "1";
    ofCanvasArea.current.style.visibility = "visible";
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleBack = () => {
    setDone(false);
  };
  useEffect(() => {
    // Function to scroll to the section based on the URL hash
    const scrollToHash = () => {
      const hash = window.location.hash.substr(1); // Get the hash from the URL without the '#'
      if (hash) {
        scrollToSection(hash);
      }
    };

    // Scroll to the section when the component mounts
    scrollToHash();

    // Scroll to the section when the URL hash changes
    window.addEventListener("hashchange", scrollToHash);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("hashchange", scrollToHash);
    };
  }, []);
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      // Get the distance of the section from the top of the page
      const offsetTop = section.getBoundingClientRect().top + window.pageYOffset;
  
      // Scroll to the section using the calculated offset
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };
  return (
    <>
      <header className={`header__area-3 ${isSticky ? "sticky-3" : ""}`}>
        <div className="header__inner-3">
          <div className="header__logo-2">
            <a href="#" className="logo-dark">
              <img
                className="dainty-header-logo"
                src={site_logo}
                alt="Site Logo"
              />
            </a>
            <a href="#" className="logo-light">
              <img
                src="assets/imgs/logo/site-logo-white-2.png"
                alt="Site Logo"
              />
            </a>
          </div>
          <div className="header__nav-2">
            <ul className="main-menu-3 menu-anim">
              <li className="has-megamenu">
                <a href="#" onClick={() => scrollToSection("home")}> Home </a>
              </li>
              <li>
                <a href="#plots" onClick={() => scrollToSection("plots")}> Plots </a>
              </li>
              <li className="has-megamenu">
                <a href="#about-dholera" onClick={() => scrollToSection("about-dholera")}> About Dholera </a>
              </li>
              <li>
                <a href="#projects" onClick={() => scrollToSection("projects")}> Projects </a>
              </li>
              <li>
                <a href="#testimonials" onClick={() => scrollToSection("testimonials")}> Testimonials </a>
              </li>
              <li>
                <a href="#why-dainty" onClick={() => scrollToSection("why-dainty")}> Why Dainty </a>
              </li>
            </ul>
          </div>
          <div className="header__nav-icon-3">
            <button className="search-icon" id="search_icon">
              <i className="fa-solid fa-magnifying-glass" />
            </button>
            <button className="search-icon" id="search_close">
              <i className="fa-solid fa-xmark" />
            </button>
            <button className="btn-buynow" onClick={openPopup}>
              Invest Now
            </button>
            <button onClick={openCanvas} id="open_offcanvas">
              <img src={menu_black} alt="Menubar Icon" />
            </button>
          </div>
        </div>
      </header>
      {isPopupOpen && (
        <ContactPopUp
          setDone={setDone}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          closePopup={closePopup}
        />
      )}
      {done && <ThankYou onBack={handleBack} />}
      <Canvas ofCanvasArea={ofCanvasArea} />
    </>
  );
};

export default HeaderMain;

