import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import brandone from "../assets/imgs/brand/dholera-brands/Avaada.jpg"
import brandtwo from "../assets/imgs/brand/dholera-brands/AECOM.jpg"
import brandthree from "../assets/imgs/brand/dholera-brands/Foxconn1.jpg"
import brandfour from "../assets/imgs/brand/dholera-brands/HCL.jpg"
import brandfive from "../assets/imgs/brand/dholera-brands/Renew-Power.jpg"
import brandsix from "../assets/imgs/brand/dholera-brands/Tata-Power3.jpg"
import brandseven from "../assets/imgs/brand/dholera-brands/Torrent-Power.jpg"
import brandeight from "../assets/imgs/brand/dholera-brands/Tsings.jpg"
import brandnine from "../assets/imgs/brand/dholera-brands/Vestas.jpg"
import brandten from "../assets/imgs/brand/dholera-brands/CISCO2.jpg"
import brandeleven from "../assets/imgs/brand/dholera-brands/halcrow.jpg"
import brandtwelve from "../assets/imgs/brand/dholera-brands/wipro.jpg"
import "../assets/css/textcontent.css"
gsap.registerPlugin(ScrollTrigger);

const GlobalBrands = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      setTimeout(() => {
        let tHero = gsap.context(() => {
          let animation__feature2 = gsap.utils.toArray(
            ".brand__area .animation__plot"
          );
          if (device_width < 1023) {
            animation__feature2.forEach((item, i) => {
              gsap.set(item, { opacity: 0, y: 60 });
              let featured2Timeline = gsap.timeline({
                scrollTrigger: {
                  trigger: item,
                  start: "top center+=200",
                },
              });
              featured2Timeline.to(item, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
              });
            });
          } else {
            gsap.set(".brand__area .animation__plot", {
              opacity: 0,
              y: 40,
            });
            gsap.to(".brand__area .animation__plot", {
              scrollTrigger: {
                trigger: ".brand__area",
                start: "top center+=200",
              },
              opacity: 1,
              y: 0,
              duration: 2,
              ease: "power4.out",
              stagger: 0.3,
            });
          }
        });
        return () => tHero.revert();
      }, 1000);
    }
  }, []);
  return (
    <>
      <div className="has-smooth" id="has_smooth" />
    <div id="projects">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <section className="brand__area">
            <div className="container g-0 line pt-140 pb-130">
              <span className="line-3" />
              <div className="row animation__plot">
                <div className="col-xxl-12">
                  <div className="sec-title-wrapper">
                    <h2 className="sec-sub-title title-anim animation__char_come">
                    Big Brands, Big Projects, Growth of Dholera
                    </h2>
                    <h3 className="sec-title title-anim custom-h3 smart-txt animation__char_come">
                    Yes, Big Brands are coming to Dholera
                    </h3>
                  </div>
                </div>
                <div className="col-xxl-12">
                  <div className="brand__list">
                    <div className="brand__item fade_bottom">
                      <img src={brandone}  className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandtwo} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandthree} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandfour} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandfive} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandsix} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandseven} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandeight} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandnine} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandten} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandeleven} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                    <div className="brand__item fade_bottom">
                      <img src={brandtwelve} className="w120 animation__char_come" alt="Brand Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
    </>
  );
};

export default GlobalBrands;
