

import React from "react";
import { TweenLite, Power4 } from "gsap";

export default class SmoothScroll extends React.Component {
  state = {
    height: this.calculateViewportHeight(),
    isPopBackgroundActive: false,
    isScrollEnabled: true // Added state variable to control scrolling
  };

  ro = new ResizeObserver(elements => {
    for (let elem of elements) {
      const crx = elem.contentRect;
      this.setState({
        height: crx.height
      });
    }
  });

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    this.ro.observe(this.viewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    this.ro.disconnect();
  }

 

  onScroll = () => {
   
      TweenLite.to(this.viewport, 1, {
        y: -window.pageYOffset,
        ease: Power4.easeOut
      });

  };

  calculateViewportHeight() {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }

  disableScroll() {
    // Disable scrolling
    window.addEventListener("scroll", this.preventScroll, false);
  }

  enableScroll() {
    // Enable scrolling
    window.removeEventListener("scroll", this.preventScroll, false);
  }

  preventScroll = () => {
    // Prevent scrolling
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="viewport" ref={ref => (this.viewport = ref)}>
          {this.props.children}
        </div>
        <div
          ref={ref => (this.fake = ref)}
          style={{
            height: this.state.height
          }}
        />
      </>
    );
  }
}
