import React from "react";
import story from "../assets/dainty-grp/Dharampal-Singh-_1_.webp";
import { useRef } from "react";
import { useEffect } from "react";
import { Power1, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../assets/css/chooseus.css";
gsap.registerPlugin(ScrollTrigger);

const ChooseUs = () => {
  const workflowWrapper = useRef();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device_width = window.innerWidth;
      let tHero = gsap.context(() => {
        if (device_width > 1200) {
          var workflow_section_3 = workflowWrapper.current;
          if (workflow_section_3) {
            let duration = 1,
              sections = gsap.utils.toArray(".wf_panel"),
              sectionIncrement = duration / (sections.length - 1),
              tl = gsap.timeline({
                scrollTrigger: {
                  trigger: ".workflow__wrapper-3",
                  pin: true,
                  scrub: 1,
                  start: "top top",
                  end: "+=5000",
                },
              });

            tl.to(sections, {
              xPercent: -100 * (sections.length - 1),
              duration: duration,
              ease: "none",
            });

            sections.forEach((section, index) => {
              let tween = gsap.from(section, {
                opacity: 0,
                scale: 0.6,
                duration: 0.5,
                force3D: true,
                paused: true,
              });
              addSectionCallbacks(tl, {
                start: sectionIncrement * (index - 0.99),
                end: sectionIncrement * (index + 0.99),
                onEnter: () => tween.play(),
                onLeave: () => tween.reverse(),
                onEnterBack: () => tween.play(),
                onLeaveBack: () => tween.reverse(),
              });
              index || tween.progress(1);
            });

            function addSectionCallbacks(
              timeline,
              { start, end, param, onEnter, onLeave, onEnterBack, onLeaveBack }
            ) {
              let trackDirection = (animation) => {
                  let onUpdate = animation.eventCallback("onUpdate"),
                    prevTime = animation.time();
                  animation.direction = animation.reversed() ? -1 : 1;
                  animation.eventCallback("onUpdate", () => {
                    let time = animation.time();
                    if (prevTime !== time) {
                      animation.direction = time < prevTime ? -1 : 1;
                      prevTime = time;
                    }
                    onUpdate && onUpdate.call(animation);
                  });
                },
                empty = (v) => v;
              timeline.direction || trackDirection(timeline);
              start >= 0 &&
                timeline.add(
                  () =>
                    ((timeline.direction < 0 ? onLeaveBack : onEnter) || empty)(
                      param
                    ),
                  start
                );
              end <= timeline.duration() &&
                timeline.add(
                  () =>
                    ((timeline.direction < 0 ? onEnterBack : onLeave) || empty)(
                      param
                    ),
                  end
                );
            }
          }
          for (let i = 1; i < 5; i++) {
            gsap.from(gsap.utils.toArray(`.count${i}`), {
              textContent: 0,
              duration: 1,
              delay: 3,
              ease: Power1.easeIn,
              snap: { textContent: 1 },
              stagger: 1,
              scrollTrigger: {
                trigger: ".counter__number",
              },
            });
          }
        }
      });
      return () => tHero.revert();
    }
  }, []);
  return (
    <>
     
      <div className="has-smooth" id="has_smooth" />
      {/* Go Top Button */}
      <button id="scroll_top" className="scroll-top">
        <i className="fa-solid fa-arrow-up" />
      </button>
    <div id="why-dainty">
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <section className="workflow__area-3">
            <div className="workflow__wrapper-3" ref={workflowWrapper}>
              <div className="choose-wrapper wf_panel">
                <div className="container">
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="choose-title-wrapper">
                        <h5 className="choose-title title-anim animation__char_come">
                          Why Choose <br /> DAINTY GROUP
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="research__area wf_panel pt-150">
                <div className="container inner_content">
                  <div className="row tab-inner-box">
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="sec-title-wrapper">
                        <h2 className="sec-sub-title animation__char_come">
                          About <br />
                          us
                        </h2>
                        <h3 className="sec-title animation__char_come">
                          Dainty Group: Your Trusted Partner for Investing in
                          The Future of Dholera
                        </h3>
                        <p className="animation__char_come">
                          At Dainty Group, our primary focus revolves around
                          ensuring the utmost satisfaction of our clientele. We
                          prioritize understanding their aspirations,
                          objectives, and evolving needs. As we adapt to the
                          ongoing transformations within Dholera SIR, we
                          recognize our crucial role in enhancing the quality of
                          life in this area. Our team is committed to delivering
                          the best outcomes for our clients, continuously
                          refining our approaches, endeavors, and strategies to
                          create sustainable and forward-thinking environments
                        </p>
                      </div>
                      {/* <ul className="research__tools">
                        <li>
                          <a href="#">Google</a>
                        </li>
                        <li>
                          <a href="#">pinterest</a>
                        </li>
                        <li>
                          <a href="#">Instagram</a>
                        </li>
                      </ul> */}
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="research__list">
                        <div className="research__item animation__char_come">
                          <div className="research__number">
                            <span>100%</span>
                          </div>
                          <div className="research__info">
                            <h4 className="research__title">
                              Client Satisfaction
                            </h4>
                            <p>
                              It includes feedback on responsiveness,
                              communication, meeting expectations, and overall
                              experience. Client satisfaction serves as a key
                              indicator of our effectiveness in meeting client
                              needs and fostering positive relationships.
                            </p>
                          </div>
                        </div>
                        <div className="research__item animation__char_come">
                          <div className="research__number">
                            <span>100%</span>
                          </div>
                          <div className="research__info">
                            <h4 className="research__title">
                              Market Knowledge
                            </h4>
                            <p>
                              The depth of our understanding of the local real
                              estate market, including trends, demand-supply
                              dynamics, pricing fluctuations, and potential
                              investment opportunities.
                            </p>
                          </div>
                        </div>
                        <div className="research__item animation__char_come">
                          <div className="research__number">
                            <span>100%</span>
                          </div>
                          <div className="research__info">
                            <h4 className="research__title">
                              Legal Compliance
                            </h4>
                            <p>
                              our ability to navigate complex legal frameworks,
                              ensure transparent dealings, and mitigate risks
                              associated with land acquisition, zoning
                              regulations, permits, and other compliance
                              matters. Compliance with legal standards is
                              crucial for building trust with clients and
                              safeguarding their investments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="counter__area-3 wf_panel">
                <div className="container">
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="sec-title-wrapper">
                        <h2 className="sec-sub-title founder-choose-us animation__char_come">
                          Why <br />
                          Choose Us
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 founder-box">
                      <div className="counter__wrapper-3">
                        <div className="counter__item-3 animation__char_come">
                          <h2 className="counter__number">125+</h2>
                          <p className="counter-txt-3">
                            Deals Closed <br />
                            In Dholera
                          </p>
                        </div>
                        <div className="counter__item-3 animation__char_come">
                          <h2 className="counter__number">250+</h2>
                          <p className="counter-txt-3">
                            Trusted Clients <br />
                            Across India
                          </p>
                        </div>
                        <div className="counter__item-3 animation__char_come">
                          <h2 className="counter__number">30</h2>
                          <p className="counter-txt-3">
                            Years <br />
                            experiences
                          </p>
                        </div>
                        <div className="counter__item-3 animation__char_come">
                          <h2 className="counter__number">250Cr+</h2>
                          <p className="counter-txt-3">
                            Investment Done <br />
                            Tille Date
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                      <div className="counter__img-3 animation__char_come">
                        <img className="img-founder" src={story} alt="Counter Image" />
                      </div>
                      <div>
                        <p className="founder-txt animation__char_come">
                        Mr. Dharampal Singh founder and chairman of Dainty Smart Investments started his journey in the real estate industry back in 2002 since then his reputation for excellence has been commendable in the industry, having more than two decades of experience as a real estate broker Mr. Dharampal Singh has played with every corner of India, what sets Mr. Dharampal Singh apart is his self-motivated attitude. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* CTA area start */}
              <div className="cta__area-3 wf_panel">
                <div className="container pt-150 pb-150">
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="cta__content-3">
                        <p className="cta__sub-title-2 animation__char_come">
                          Want to Invest in Dholera?
                        </p>
                        <h2 className="cta__title-2 animation__char_come">
                          Let's craft your Investment in Dholera together!
                        </h2>
                        <div className="btn_wrapper btn-invest btn-contact">
                          <a
                          onClick={scrollToTop}
                            href="#contact-us"
                            className="wc-btn-black btn-hover btn-item btn-choose-contact"
                          >
                            <span />
                            Contact <br />
                           us <i className="fa-solid fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>  
      {/* All JS files */}
    </>
  );
};

export default ChooseUs;
