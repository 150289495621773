import React from "react";
import site_logo from "../assets/dainty-grp/dainty-_1_ (1).webp";
import address from "../assets/dainty-icons/location.png";
import call from "../assets/dainty-icons/phone-call.png";
import mail from "../assets/dainty-icons/mail.png";
import "../assets/css/footer.css";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
 
  return (
    <>
      {/* Scroll Smoother */}
      <div className="has-smooth" id="has_smooth" />
      {/* Go Top Button */}
      <button id="scroll_top" className="scroll-top">
        <i className="fa-solid fa-arrow-up" />
      </button>

      <div id="smooth-wrapper">
        <div id="smooth-content">
          <div className="footer-full-width">
            <footer className="footer__area">
              <div className="footer__btm">
                <div className="container">
                  <div className="row footer__row">
                    <div className="col-xxl-12">
                      <div className="footer__inner">
                        <div className="footer__widget">
                          <img
                            className="footer__logo"
                            src={site_logo}
                            alt="Footer Logo"
                          />

                          {/* <h2 className="footer__widget-title">Useful Links</h2>
                          <ul className="footer__useful__link">
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a
                                  target="_blank"
                                  href="https://dholera.gujarat.gov.in/"
                                >
                                  dholera.gujarat.gov.in
                                </a>
                              </li>
                            </div>
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a target="_blank" href="https://www.gidb.org/">
                                  www.gidb.org
                                </a>
                              </li>
                            </div>
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a target="_blank" href="https://www.nicdc.in/">
                                  www.nicdc.in
                                </a>
                              </li>
                            </div>
                          </ul> */}
                        </div>
                        <div className="footer__widget-2">
                          {/* <h2 className="footer__widget-title">Useful Links</h2>
                          <ul className="footer__useful__link">
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a
                                  target="_blank"
                                  href="https://dholera.gujarat.gov.in/"
                                >
                                  dholera.gujarat.gov.in
                                </a>
                              </li>
                            </div>
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a target="_blank" href="https://www.gidb.org/">
                                  www.gidb.org
                                </a>
                              </li>
                            </div>
                            <div className="svg-bollet-dholera">
                              <div
                                className="svg-pad-t-p"
                                dangerouslySetInnerHTML={{ __html: bollet }}
                              />
                              <li>
                                <a target="_blank" href="https://www.nicdc.in/">
                                  www.nicdc.in
                                </a>
                              </li>
                            </div>
                          </ul> */}
                           <div>
                           <h2 className="footer__widget-title social_media_dainty">Company</h2>
                            <ul className="footer__useful__link">
                              <li>
                              <div className="svg-bollet-dholera">
                             
                                <a target="_blank" href="#">
                                 About us
                                </a>
                                </div>
                              </li>
                              <li>
                              <div className="svg-bollet-dholera">
                            
                                <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                                </div>
                              </li>
                              <li>
                              <div className="svg-bollet-dholera">
                             
                                <a target="_blank" href="/disclaimer">Disclaimer</a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="footer__widget-3">
                          <h2 className="footer__widget-title contact-title">
                            Contact Us
                          </h2>
                          <ul className="footer__contact">
                            <li>
                              <img
                                className="footer-contact-icons"
                                src={address}
                                alt=""
                              />{" "}
                              &nbsp;&nbsp; B1, 504, WestGate Business Bay,
                              Makarba, Ahmedabad, Gujarat-380051
                            </li>

                            <li>
                              <img
                                className="footer-contact-icons"
                                src={call}
                                alt=""
                              />{" "}
                              &nbsp;&nbsp;
                              <a href="tel:02574328301" className="phone">
                                +91 98025 11001
                              </a>
                            </li>
                            <li>
                              <img
                                className="footer-contact-icons"
                                src={mail}
                                alt=""
                              />{" "}
                              &nbsp;&nbsp;
                              <a href="mailto: daintydholeraproperties@gmail.com">
                              daintydholeraproperties@gmail.com
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="footer__widget-4 lap-view-widget-4">
                          <h2 className="project-title">
                          Let's start your investment journey!
                          </h2>
                          <button onClick={scrollToTop} className="btn_wrapper">
                            <a
                              href="#"
                              className="wc-btn-primary btn-hover btn-item btn-footer"
                            >
                              <span /> contact us{" "}
                              <i className="fa-solid fa-arrow-right" />
                            </a>
                          </button>
                        </div>

                        {/* <div className="footer__subscribe"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
          {/* Footer area end */}
        </div>
      </div>
    </>
  );
};

export default Footer;
